import React, { useEffect, useState } from "react";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../store/AuthStore";
import axios from "axios";
import { BASE } from "../lib/apiBase";
function Login() {
  const { setUserLogin, loggedIn } = useAuth();
  const [userID, setUserId] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // loggedIn && navigate("/billuser");
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!(userID && pass)) {
      return;
    }
    const postData = {
      id: userID,
      password: pass,
    };
    try {
      const { data } = await axios.post(`${BASE}/auth/login`, postData);
      if (!data?.success) {
        throw new Error(data.message);
      }
      if (window?.ReactNativeWebView?.postMessage) window.ReactNativeWebView.postMessage('login-success');
      setUserLogin(data?.user);
      navigate("/billuser", { replace: true });
    } catch (e) {
      console.log(e);
    }

    // if (dataID.username === userID && dataID.password === pass) {
    //   navigate("/billuser");
    // } else {
    //   alert("FORM ERROR");
    // }
  };

  return (
    <Flex justify='center' align='center'>
      <div className='formLogin'>
        <div className='containerForm'>
          <div className='Title'>
            <h2>
              <span>로그인</span>
              <span>후</span> 이용이 가능합니다.
            </h2>
          </div>
          <div className='formContent'>
            <label htmlFor='id' className='label_ID'>
              아이디
            </label>
            <input
              type='text'
              name='id'
              id='id'
              placeholder='아이디를 입력하세요. '
              onChange={(e) => setUserId(e.target.value)}
            />

            <label htmlFor='password' className='label_Password'>
              비밀번호
            </label>
            <input
              type='password'
              name='password'
              id='password'
              placeholder='패스워드를 입력하세요. '
              onChange={(e) => setPass(e.target.value)}
            />

            <button type='submit' className='btn_Login' onClick={handleLogin}>
              로그인
            </button>
          </div>
        </div>
      </div>
    </Flex>
  );
}

export default Login;
