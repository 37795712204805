import React, { useState, useRef } from "react";

import iconNote from "../images/Note.svg";
import iconClock from "../images/clock.png";
import iconAttach from "../images/Attach.svg";
import iconCart from "../images/Cart.svg";
import iconAccept from "../images/approve-bill-svgrepo-com.svg";
import styles from "../css/Bill.module.css";
import { useAuth } from "../store/AuthStore";
import axios from "axios";
import { BASE } from "../lib/apiBase";
// import html2canvas from "html2canvas";


const BillUserItem = ({ item, propsClickAccept, handleADD }) => {
  const { user, loggedIn, logout } = useAuth();
  const [disable, setDisable] = useState(true);
  const ref = useRef(null);


  const handleClickAcceptItem = (orderId) => async() => {
    propsClickAccept(orderId);
    setDisable(false);
    try {
       await axios.put(`${BASE}/order/${item._id}`, {
        orderStatus: "준비중",
      });

      const data = {...item, branchName: user.name }
    if (window?.ReactNativeWebView?.postMessage) {
      // const canvas = await html2canvas(ref.current);
      // const image = canvas.toDataURL("image/png", 1.0);
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
     
    } catch (e) {
      console.log(e);
    }
    
  };

  const handleClickPrintBill = (item, id) => async () => {

    handleADD(id);
  };

  return (
    <>
    <div className="Bill_Detail" key={item._id}>
      <div ref={ref}>
        <div className="time">
          <div className="idBill">
            <img src={iconNote} alt="ID_BILL" />
            <p>{item.phone.slice(-4)}</p>
          </div>
          <div className="detailTime">
            <div className="hours">
              <img src={iconClock} alt="Clock" />
              <p>{new Date(item.createdAt).toLocaleTimeString()}</p>
            </div>
            <div className="date">
              Date : {new Date(item.createdAt).toLocaleDateString()}
            </div>
          </div>
        </div>
        <div className="Detail_Drink">
          <div className="Container_Detail">
            {item.orderItems.map((v) => {
              const totalOptionPrice = JSON.parse(v.option).reduce(
                (total, op) => {
                  return op.optionPrice
                    ? total + Number(op.optionPrice)
                    : total;
                },
                0
              );
              return (
                <div key={v._id} className="ContainerDrink">
                  <div className="NameDrink">
                    <p>{v.menuName}</p>
                    
                      <p>수량 : <span style={{fontWeight: 400,fontFamily: 'Pretendard'}}>{v.quantity}</span></p>
                    
                    <div>
                      <p>{Number(v.price).toLocaleString()}원</p>
                    </div>
                  </div>
                  {JSON.parse(v.option).map((op, index) => (
                    <div className="OptionDrink NameDrink" key={index}>
                      <p>
                        {op.optionName && (
                          <>
                            {" "}
                            <img src={iconAttach} alt="" />
                            <span>{op.optionGroupName}</span>{" "}
                          </>
                        )}

                        {op.optionName && (
                          <span style={{ marginLeft: "5px" }}>
                            {" "}
                            {op.optionName}
                          </span>
                        )}
                      </p>
                      <div>
                        {op.optionName && (
                          <p>{Number(op.optionPrice).toLocaleString()}원</p>
                        )}
                      </div>
                    </div>
                  ))}
                  {totalOptionPrice !== 0 && (
                    <p
                      style={{
                        textAlign: "end",
                        paddingTop: "10px",
                        fontSize: "14px",
                        borderTop: "1px dashed rgba(0, 0, 0, 0.053)",
                        marginTop: "15px",
                      }}
                    >
                      {(Number(v.price) + totalOptionPrice).toLocaleString()}원
                    </p>
                  )}
                </div>
              );
            })}
          </div>

          <div className="Total">
            <p>Total</p>
            <p>{Number(item.totalAmount).toLocaleString()}원</p>
          </div>
        </div>
      </div>

      <div className={styles["wrapBtn"]}>
        <button
          className={styles["btn"]}
          onClick={handleClickAcceptItem(item.orderId)}
          disabled={!disable ? true : false}
        >
          <img src={iconAccept} alt="accept" />
          <div>접수</div>
        </button>
        <button
          disabled={disable}
          className={styles["btn"]}
          onClick={handleClickPrintBill(item, item._id)}
        >
          <img src={iconCart} alt="Cart" />
          <div>준비완료</div>
        </button>
      </div>
    </div>
    </>
  );
};

export default BillUserItem;
