import React, { useEffect, useState, useRef } from "react";
import iconLogout from "../images/Logout.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../store/AuthStore";
import axios from "axios";
import { BASE, BASE_SOCKET } from "../lib/apiBase";
import io from "socket.io-client";
import BillUserItem from "./BillUserItem";
import mp3 from "../access/Elsa.wav";
import soundMp3 from "../access/bell-98033.mp3";

function BillUser() {
  const { user, loggedIn, logout } = useAuth();
  const [data, setData] = useState([]);
  const [sound, setSound] = useState(false);
  console.log("🚀 ~ BillUser ~ data:", data);
  const [socket, setSocket] = useState(null);
  const [edit, setEdit] = useState(true);
  const ref = useRef(null);
  const refListOrderId = useRef([]);
  const audioRef = useRef(null);

  const navigate = useNavigate();
  const id = user?._id;

  const toDay = new Date();
  const year = toDay.getFullYear();
  const month = String(toDay.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하기 때문에 +1이 필요하고, 한 자리 수일 경우 앞에 '0'을 붙입니다.
  const date = String(toDay.getDate()).padStart(2, "0"); // 일이 한 자리 수일 경우 앞에 '0'을 붙입니다.
  const formattedDate = `${year}-${month}-${date}`;
  const BranchOrderData = async () => {
    try {
      const { data } = await axios.get(
        `${BASE}/branch/orderget?branch=${id}&today=${formattedDate}`
      );
      if (!data?.success) {
        throw new Error(data.message);
      }
      setData(() => data.orderItemsData);
      if (audioRef.current) audioRef.current.play();  
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    BranchOrderData();
    setSocket(io(BASE_SOCKET));
    ref.current = io(BASE_SOCKET);
    ref.current.on("getNewOrderSocket", (data) => {
      console.log("🚀 ~ ref.current.on ~ text:", data)
      BranchOrderData();
      if (data.text === "play" && data.branchId === id) setSound(true);
      else setSound(false);
    });
    // setSocketId(() => socket.id);
    !loggedIn && navigate("/");
  }, []);

  useEffect(() => {
    socket?.emit("newUser", user._id);
  }, [socket, user]);

  useEffect(() => {
    const requestPermission = (data) => {
      if (window.ReactNativeWebView) {
        // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
      // else {
      //   // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
      //   alert({ message: "error" });
      // }
    };

    socket?.on("getNewOrder", (data) => {
      if (data?.branchId === user._id) {
        console.log("getNewOrder : ", data);
        requestPermission(data);
        BranchOrderData();
        // setEdit((prev) => !prev);
        // 프린터
      }
    });
  }, [socket]);

  useEffect(() => {
    BranchOrderData();
  }, [edit]);

  const handleADD = async (id) => {
    const datafill = data.filter((item) => item._id !== id);
    try {
      const { data } = await axios.put(`${BASE}/order/${id}`, {
        orderStatus: "준비완료",
      });
      console.log(data);
      socket.emit("newPickup", {
        branchId: data.branchId,
        userId: data.userId,
        pData: data,
      });
      setSound(false)
      setEdit(!edit);
    } catch (e) {
      console.log(e);
    }
    setData(datafill);
  };
  const handleClickAccept = (orderId) => {
    refListOrderId.current = [...refListOrderId.current, orderId];
    ref.current.emit("emitOrderId", refListOrderId.current);
    setSound(false)
  };

  return (
    <div className="container">
      {console.log("sound: ", sound)}
      <div className="header">
        {" "}
        <h1>주문 목록</h1>
        <div className="idUser">
          <div className="name">{user.name}</div>
          <img src={iconLogout} alt="Logout" onClick={() => logout()} />
        </div>
      </div>
      <div className="container_Bill">
        <div className="scroll_container">
          {data?.map((item) => (
            <BillUserItem
              key={item.orderId}
              item={item}
              propsClickAccept={handleClickAccept}
              handleADD={handleADD}
            />
          ))}
        </div>
      </div>
      <div className="footerReception">
        <p>접수대기</p>
        <span>{data.length}</span>
      </div>
      {
        sound ? <audio ref={audioRef} src={mp3} /> :  <audio ref={audioRef} src={soundMp3} muted={true}/>
      }
     
    </div>
  );
}

export default BillUser;
